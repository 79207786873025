import { ILessonLectorEditDTO, ILessonScheduleAddDTO, IScheduleFromAddDTO } from '../../models/lesson.interface';
import { IGroup } from '../../models/group.interface';

export class SetLessonsScheduleGroup {
    public static readonly type = '[LessonsSchedule] SetLessonsScheduleGroup';
    constructor(public payload: { group: IGroup }) {}
}

export class ReloadLessonsScheduleGroup {
    public static readonly type = '[LessonsSchedule] ReloadLessonsScheduleGroup';
}

export class LoadLessonsSchedule {
    public static readonly type = '[LessonsSchedule] LoadLessonsSchedule';
}

export class ResetLessonsSchedule {
    public static readonly type = '[LessonsSchedule] ResetLessonsSchedule';
}

export class EditLessonSchedule {
    public static readonly type = '[LessonsSchedule] EditLessonSchedule';
    constructor(public payload: { dto: ILessonScheduleAddDTO }) {}
}

export class EditLessonLector {
    public static readonly type = '[LessonsSchedule] EditLessonLector';
    constructor(public payload: { dto: ILessonLectorEditDTO }) {}
}

export class AddLessonSchedule {
    public static readonly type = '[LessonsSchedule] AddLessonSchedule';
    constructor(public payload: { dto: ILessonScheduleAddDTO }) {}
}

export class DeleteLessonSchedule {
    public static readonly type = '[LessonsSchedule] DeleteLessonSchedule';
    constructor(public payload: { id: number }) {}
}

export class ChangeScheduleFrom {
    public static readonly type = '[LessonsSchedule] ChangeScheduleFrom';
    constructor(public payload: { dto: IScheduleFromAddDTO }) {}
}
